<template>
    <div class="pa-0 ma-0 white">
      <div class="my-border" style="min-height:calc(100vh - 130px)">
        <v-tabs grow
            v-model="tabs"
            background-color="white" 
            justify="center" 
            slider-color="custom-color-accent"
            color="#046e8b"
            show-arrows
            >
            <v-tab class="text-wrap"
              href="#aboutaxcess1">
              <v-icon left>
                mdi-information 
              </v-icon>
               <span v-if="!isMobile">{{$t("aboutaxcess1")}}</span>
            </v-tab>
            <v-tab class="text-wrap"
              href="#install">
              <v-icon left>
                mdi-server
              </v-icon>
              <span v-if="!isMobile">{{$t("install")}}</span>
            </v-tab>
            <v-tab class="text-wrap"
              href="#userguide">
              <v-icon left>
                mdi-book-account
              </v-icon>
              <span v-if="!isMobile">{{$t("userguide")}}</span>
            </v-tab>
          
              <v-tabs-items v-model="tabs" class="ma-0 pa-0 transparent">
                <div class="pa-0 ma-0 py-6">
                    <v-tab-item
                      :key="1"
                      :value="'aboutaxcess1'"
                      >
                      <v-row class="pa-0 ma-0">
                        <v-col cols="12" sm="12" md="8" lg="8" class="ma-auto pa-0">
                            <iframe :src="`${publicPath}${about}`" class="viewer my-border pa-0 ma-0" ></iframe>
                        </v-col>
                      </v-row>
                    </v-tab-item>
                    <v-tab-item
                      :key="2"
                      :value="'install'"
                      >
                      <v-row class="pa-0 ma-0">
                        <v-col cols="12" sm="12" md="8" lg="8" class="ma-auto pa-0">
                            <iframe :src="`${publicPath}${install}`" class="viewer my-border pa-0 ma-0" ></iframe>
                        </v-col>
                      </v-row>
                    </v-tab-item>
                    <v-tab-item
                      :key="3"
                      :value="'userguide'"
                      >
                      <v-row class="pa-0 ma-0">
                        <v-col cols="12" sm="12" md="8" lg="8" class="ma-auto pa-0">
                            <iframe :src="`${publicPath}${user}`" class="viewer my-border pa-0 ma-0" ></iframe>
                        </v-col>
                      </v-row>
                    </v-tab-item>
                </div>
              </v-tabs-items>
          </v-tabs>
      </div>
    </div>

</template>

<script>

//import pdf from 'vue-pdf'

export default {
  name: "About",
  components: {
   //pdf
  },
  data() {
    return {
      tabs:null,
      isMobile: false,  
      publicPath: process.env.BASE_URL,
      install:"Axcess-1 Installation and Administration Guide.pdf",
      about:"About Axcess-1.pdf",
      user:"Axcess-1 User Guide.pdf",
    };
  },
  mounted() {
        if(this.$i18n.locale=="fr"){
          this.install="Axcess-1 Installation and Administration Guide.fr.pdf";
          this.about="About Axcess-1-fr.pdf";
          this.user="Axcess-1 User Guide.fr.pdf";
        }
        else{
          this.install="Axcess-1 Installation and Administration Guide.pdf";
          this.about="About Axcess-1.pdf";
          this.user="Axcess-1 User Guide.pdf";
        }
        this.onResize()
        window.addEventListener('resize', this.onResize, { passive: true })
  }, 
  beforeDestroy () {
    if (typeof window === 'undefined') return

    window.removeEventListener('resize', this.onResize, { passive: true })
  },
  methods: {
    onResize () {
      this.isMobile = window.innerWidth < 600
    },
  },
  computed: {


    
  }
};
</script>
<style lang="scss" scoped>
.viewer{display: block; width: 100%; height: 80vh;margin: auto;
}
.text-wrap{
    word-break: break-word;
}
</style>